
export const resumeData = {
    experienceList: [
        {
            title: 'Software Developer',
            //title: 'Software Developer',
            company: 'Jochen Schweizer mydays Group',
            date: '2018 - Ongoing',
            description: [
                "Multi tenant experience booking platform development.",
                "Front-End / Back-End development.",
                "Software testing.",
                "Micro-service development.",
                "DevOps."
            ]
        },
        {
            // title: 'Solutions Analyst',
            title: 'Cyber Security',
            company: 'Deloitte GmbH',
            date: '05/2017 - 06/2017',
            description: [
                "Installation of a minicomputer (Teensy).",
                "Conversion and programming of a USB mouse for uploading payloads to a PC.",
                "Consideration of malware hardware.",
                "Selection of relevant payloads.",
                "Soldering USB hub to mouse.",
                "Programming and testing of Teensy with selected payloads."
            ]
        },
        {
            // title: 'eResearch IT Support',
            title: 'Service and assembly',
            company: 'Aufzugswerke Schmitt+Sohn GmbH&Co.KG.',
            date: '07/2017 - 08/2017',
            description: [
                "Activities of a new plant fitter.",
                "Assembly of elevator systems."
            ]
        },
    ],
    projectsList: [
        {
            title: 'Covid19-cases – Coronavirus Live Map',
            description: "This script developed for all types of Corona virus-infected countries report. This report automatically generates data from trusted API sources like World Helth Organization.",
            skills: "PHP, laravel-framework, javascript, Rest APIs, docker, minikube, css, Git, mysql",
            linkHref: "https://github.com/Refaat-alktifan/covid19-cases",
            linkText: "githubb link"
        },
        {
        title: 'TheMediaPlayer Android app',
            description: "Android app for playing music.",
            skills: "java, firebase, xml",
            linkHref: "https://github.com/Refaat-alktifan/TheMediaPlayer",
            linkText: "GitHub link"
        },
        {
            title: 'Quran Android app',
            description: "Android app for hearing and reading Quran.",
            skills: "java, firebase, xml",
            linkHref: "https://github.com/Refaat-alktifan/Quran2/tree/master/quran",
            linkText: "GitHub link"
        },
        {
            title: 'Arduino CNC Plotter (Drawing Machine) ',
            description: "with this project you will make a machine that could hold a pen and draw you a picture or write a text.",
            skills: "C++",
            linkHref: "https://github.com/Refaat-alktifan/cnc-plotter-arduino",
            linkText: "GitHub link"
        },
        {
            title: 'Arduino Ropoter car',
            description: "This project is a self-driving car",
            skills: "C++",
            linkHref: "https://github.com/Refaat-alktifan/Arduino_Ropoter2/",
            linkText: "GitHub link"
        },
    ]
}