import React from 'react';
import styles from './contact.module.scss';

export function Contact(props: any) {

    const { theme } = props;

    return (
        <div className={`${styles.contact} ${theme}`}>
            <div className={styles.contact_detail}>+49 15758793525</div>
            <div className={styles.contact_detail}>
                <a className={styles.contactLink} href="mailto:Refaat@Alktifna.com">Refaat@Alktifna.com</a>
            </div>
            <div className={styles.contact_detail}>
                Munich, Germany
        </div>
            <div className={styles.contact_detail}>
                <a className={styles.contactLink} href="http://github.com/refaat-alktifan">github.com/refaat-alktifan</a>
            </div>
        </div>
    )
}

export default Contact;