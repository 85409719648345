// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2aqiLQVXxE5ooPvzANWUDa{padding:0.2em;margin:auto}._1_VsYH9su259Jv1h5zL4K{font-family:\"Droid Sans Mono\";color:black;margin:0 0 0.5rem 0;font-weight:bold}\n", "",{"version":3,"sources":["webpack://src/js/components/projects/projects.module.scss"],"names":[],"mappings":"AAAA,yBACE,aAAc,CACd,WAAY,CACb,wBAGC,6BAA8B,CAC9B,WAAY,CACZ,mBAAoB,CACpB,gBAAiB","sourcesContent":[".project {\n  padding: 0.2em;\n  margin: auto;\n}\n\n.project_title {\n  font-family: \"Droid Sans Mono\";\n  color: black;\n  margin: 0 0 0.5rem 0;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"project": "_2aqiLQVXxE5ooPvzANWUDa",
	"project_title": "_1_VsYH9su259Jv1h5zL4K"
};
export default ___CSS_LOADER_EXPORT___;
