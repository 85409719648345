// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".C_KLfbFCIj8wvD83UrRcy{font-family:\"Droid Sans Mono\";color:black;font-size:3em;margin-top:0rem;margin-bottom:0rem;text-align:center}.cF22skYgUgX-bm9pDeFgY{display:flex;justify-content:center;margin-bottom:1.5rem}._1kA_TWR00deO3nJEsrO9RE{animation-name:_1kA_TWR00deO3nJEsrO9RE;animation-duration:2000ms;animation-iteration-count:infinite;opacity:1;font-size:125%}@keyframes _1kA_TWR00deO3nJEsrO9RE{from{opacity:1}to{opacity:0}}\n", "",{"version":3,"sources":["webpack://src/js/components/header/header.module.scss"],"names":[],"mappings":"AAAA,uBACE,6BAA8B,CAC9B,WAAY,CACZ,aAAc,CACd,eAAgB,CAChB,kBAAmB,CACnB,iBAAkB,CACnB,uBAGC,YAAa,CACb,sBAAuB,CACvB,oBAAqB,CACtB,yBAGC,sCAAqB,CACrB,yBAA0B,CAC1B,kCAAmC,CACnC,SAAU,CACV,cAAe,CAChB,mCAGC,KACE,SAAU,CAEZ,GACE,SAAU,CAAA","sourcesContent":[".banner__title {\n  font-family: \"Droid Sans Mono\";\n  color: black;\n  font-size: 3em;\n  margin-top: 0rem;\n  margin-bottom: 0rem;\n  text-align: center;\n}\n\n.banner {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 1.5rem;\n}\n\n.blink {\n  animation-name: blink;\n  animation-duration: 2000ms;\n  animation-iteration-count: infinite;\n  opacity: 1;\n  font-size: 125%;\n}\n\n@keyframes blink {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner__title": "C_KLfbFCIj8wvD83UrRcy",
	"banner": "cF22skYgUgX-bm9pDeFgY",
	"blink": "_1kA_TWR00deO3nJEsrO9RE"
};
export default ___CSS_LOADER_EXPORT___;
