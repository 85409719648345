// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3HNJYps8xPOV2Ap6EVW_Sc{display:flex;flex-direction:row;justify-content:space-evenly}@media screen and (max-width: 900px){._3HNJYps8xPOV2Ap6EVW_Sc{flex-direction:column}}._1aJixZuOcoYkYUp-LGRPXF{margin:auto}._1aJixZuOcoYkYUp-LGRPXF a:link,._1aJixZuOcoYkYUp-LGRPXF a:visited,._1aJixZuOcoYkYUp-LGRPXF a:active{color:rgba(0,0,0,0.6)}\n", "",{"version":3,"sources":["webpack://src/js/components/contact/contact.module.scss"],"names":[],"mappings":"AAAA,yBACE,YAAa,CACb,kBAAmB,CACnB,4BAA6B,CAC9B,qCAGC,yBACE,qBAAsB,CACvB,CAGH,yBACE,WAAY,CADd,qGAII,qBAA2B","sourcesContent":[".contact {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-evenly;\n}\n\n@media screen and (max-width: 900px) {\n  .contact {\n    flex-direction: column;\n  }\n}\n\n.contact_detail {\n  margin: auto;\n\n  a:link,a:visited,a:active {\n    color: rgba($color: #000000, $alpha: 0.6);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact": "_3HNJYps8xPOV2Ap6EVW_Sc",
	"contact_detail": "_1aJixZuOcoYkYUp-LGRPXF"
};
export default ___CSS_LOADER_EXPORT___;
