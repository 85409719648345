import React from 'react';
import styles from './miscellaneous.module.scss'
import globalStyles from '../app.module.scss';

export function Miscellaneous() {

  return (
    <div className={styles.misc}>
      <div id="skills-header" className={globalStyles.sectionTitle}>Skills && Interests</div>
      <div>
        <div className={styles.misc_title}>Skills</div>
        <ul>
          <li>
          PHP, Node.js, JavaScript, Java, SQL, C#,CSS, HTML, AWS, Docker, MongoDB, Unity, PHPUnit, Symfony, Laravel, API, JIRA, OOP, SEO, Scrum, Bash, Git
        </li>
        </ul>
      </div>
      <div className="interests">
        <div className={styles.misc_title}>Interests</div>
        <ul>
          <li>
          Reading, Unity (game development), Programming
        </li>
        </ul>
      </div>

      <div className="interests">
        <div className={styles.misc_title}>LANGUAGES</div>
        <ul>
          <li>
          Arabic(Native), German(Proficient), English(Intermediate)
        </li>
        </ul>
      </div>
    </div>
  )
}

export default Miscellaneous;