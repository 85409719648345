// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3x7YQ6azrzQ691JDMaFzgk{margin-left:0.5rem}@media screen and (max-width: 650px){._3x7YQ6azrzQ691JDMaFzgk{margin-left:0rem}}._3x7YQ6azrzQ691JDMaFzgk a:link,a:visited,a:active{color:#4a7bb4}._3x7YQ6azrzQ691JDMaFzgk a:hover{color:orange}.NFixjCxP7jTOKJtRK8h6n{white-space:nowrap;color:rgba(0,0,0,0.85);font-weight:bold;font-size:110%}._2VyNEUoCP7nnixbRMdlovg{margin-left:2rem}._1T0vuDNcC_H4IsNnOc-Sm4{display:flex;flex-direction:column;margin-bottom:1rem}._1LPZ3MTzSdcmu6dIrBcZ0A{color:rgba(0,0,0,0.493);margin-left:2rem}.ye36JMnFpf9fokioPmf69{display:flex;flex-direction:row}@media screen and (max-width: 650px){.ye36JMnFpf9fokioPmf69{flex-direction:column}}\n", "",{"version":3,"sources":["webpack://src/js/components/projects/project/project.module.scss"],"names":[],"mappings":"AAAA,yBACE,kBAAmB,CACnB,qCAFF,yBAGI,gBAAiB,CAEpB,CAED,mDAGE,aAAc,CACf,iCAGC,YAAa,CACd,uBAGC,kBAAmB,CACnB,sBAA0B,CAC1B,gBAAiB,CACjB,cAAe,CAKhB,yBAGC,gBAAiB,CAClB,yBAGC,YAAa,CACb,qBAAsB,CACtB,kBAAmB,CACpB,yBAGC,uBAA2B,CAC3B,gBAAiB,CAClB,uBAGC,YAAa,CACb,kBAAmB,CACnB,qCAHF,uBAII,qBAAsB,CAEzB","sourcesContent":[".project_link {\n  margin-left: 0.5rem;\n  @media screen and (max-width: 650px) {\n    margin-left: 0rem;\n  }\n}\n\n.project_link a:link,\na:visited,\na:active {\n  color: #4a7bb4;\n}\n\n.project_link a:hover {\n  color: orange;\n}\n\n.project_title {\n  white-space: nowrap;\n  color: rgba(0, 0, 0, 0.85);\n  font-weight: bold;\n  font-size: 110%;\n\n  @media screen and (max-width: 650px) {\n    // font-weight: normal;\n  }\n}\n\n.project_description {\n  margin-left: 2rem;\n}\n\n.project {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 1rem;\n}\n\n.project_skills {\n  color: rgba(0, 0, 0, 0.493);\n  margin-left: 2rem;\n}\n\n.project_rowContainer {\n  display: flex;\n  flex-direction: row;\n  @media screen and (max-width: 650px) {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"project_link": "_3x7YQ6azrzQ691JDMaFzgk",
	"project_title": "NFixjCxP7jTOKJtRK8h6n",
	"project_description": "_2VyNEUoCP7nnixbRMdlovg",
	"project": "_1T0vuDNcC_H4IsNnOc-Sm4",
	"project_skills": "_1LPZ3MTzSdcmu6dIrBcZ0A",
	"project_rowContainer": "ye36JMnFpf9fokioPmf69"
};
export default ___CSS_LOADER_EXPORT___;
